import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  output,
  signal,
} from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { ButtonModule } from 'primeng/button';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { OverlayPanelModule } from 'primeng/overlaypanel';

import { NavbarButtonComponent } from '../navbar-button/navbar-button.component';

@Component({
  selector: 'customer-portal-navbar-search',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoDirective,
    ButtonModule,
    InputGroupModule,
    InputGroupAddonModule,
    OverlayPanelModule,
    NavbarButtonComponent,
  ],
  templateUrl: './navbar-search.component.html',
  styleUrl: './navbar-search.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarSearchComponent {
  public isButtonSearchActive = signal<boolean>(false);

  public searchEvent = output<void>();

  onSearch(): void {
    this.searchEvent.emit();
  }

  onToggleButtonSearchActive(value: boolean): void {
    this.isButtonSearchActive.set(value);
  }
}
