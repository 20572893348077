import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';

import { Language, LocaleService } from '@customer-portal/shared';

import { LanguageStorageService } from '../../services';
import { NavbarButtonComponent } from '../navbar-button/navbar-button.component';

@Component({
  selector: 'customer-portal-navbar-settings',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoDirective,
    OverlayPanelModule,
    NavbarButtonComponent,
  ],
  templateUrl: './navbar-settings.component.html',
  styleUrl: './navbar-settings.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarSettingsComponent {
  public isButtonSettingsActive = signal<boolean>(false);
  public isLanguagePickerVisible = false;
  public languages = signal([Language.English, Language.Italian]);

  constructor(
    private readonly localeService: LocaleService,
    private readonly router: Router,
    private readonly translocoService: TranslocoService,
  ) {}

  onChangeLanguage(language: Language): void {
    this.translocoService.setActiveLang(language);
    this.localeService.setLocale(language);
    LanguageStorageService.saveLanguage(language);
  }

  onChangeLanguagePickerVisibility(value: boolean): void {
    this.isLanguagePickerVisible = value;
  }

  onNavigateTo(route: string): void {
    this.router.navigate([`/${route}`]);
  }

  onToggleButtonSettingsActive(value: boolean): void {
    this.isButtonSettingsActive.set(value);

    if (!value) {
      this.onChangeLanguagePickerVisibility(false);
    }
  }

  onToggleOverlayPanel(overlayPanel: OverlayPanel, event: MouseEvent): void {
    overlayPanel.toggle(event);
  }
}
