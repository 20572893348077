<ng-container *transloco="let t">
  <customer-portal-navbar-button
    icon="cog"
    iconPosition="right"
    [isActive]="isButtonSettingsActive()"
    [label]="t('settings')"
    (clickEvent)="onToggleOverlayPanel(settingsOverlay, $event)">
  </customer-portal-navbar-button>

  <p-overlayPanel
    #settingsOverlay
    styleClass="is-caret-hidden"
    (onHide)="onToggleButtonSettingsActive(false)"
    (onShow)="onToggleButtonSettingsActive(true)">

    <ng-template pTemplate="content">
      <div
        class="container"
        [ngClass]="{ 'is-language-picker': isLanguagePickerVisible }">
        @if (isLanguagePickerVisible) {
          <customer-portal-navbar-button
            class="back"
            icon="arrow-left"
            [label]="t('back')"
            (clickEvent)="onChangeLanguagePickerVisibility(false)">
          </customer-portal-navbar-button>

          <div class="grouping">
            @for (language of languages(); track language) {
              <customer-portal-navbar-button
                [isMobileTextVisible]="true"
                [label]="t('language.' + language)"
                (clickEvent)="onChangeLanguage(language)">
              </customer-portal-navbar-button>
            }
          </div>
        } @else {
          <div class="grouping">
            <customer-portal-navbar-button
              icon="user"
              [label]="t('navbar.personalInformation')"
              (clickEvent)="onNavigateTo('settings', settingsTab.PersonalInformation)">
            </customer-portal-navbar-button>

            <customer-portal-navbar-button
              icon="building"
              [label]="t('navbar.companyDetails')"
              (clickEvent)="onNavigateTo('settings', settingsTab.CompanyDetails)">
            </customer-portal-navbar-button>

            <customer-portal-navbar-button
              icon="sitemap"
              [label]="t('navbar.members')"
              (clickEvent)="onNavigateTo('settings', settingsTab.Members)">
            </customer-portal-navbar-button>
          </div>

          <tr aria-hidden="true"></tr>

          <div class="grouping">
            <customer-portal-navbar-button
              icon="language"
              [label]="t('navbar.language')"
              (clickEvent)="onChangeLanguagePickerVisibility(true)">
            </customer-portal-navbar-button>

            <customer-portal-navbar-button
              icon="sign-out"
              [label]="t('navbar.logOut')"
              (clickEvent)="onLogoutClick()">
            </customer-portal-navbar-button>
          </div>
        }
      </div>
    </ng-template>

  </p-overlayPanel>
</ng-container>