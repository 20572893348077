import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';

import { SpinnerComponent } from '@customer-portal/core';
import { ProfileLanguageStoreService } from '@customer-portal/data-access/profile';
import {
  CustomCardComponent,
  CustomConfirmDialogComponent,
  CustomToastComponent,
} from '@customer-portal/shared';

import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SidebarMobileComponent } from './components/sidebar-mobile/sidebar-mobile.component';

@Component({
  standalone: true,
  imports: [
    ButtonModule,
    RouterModule,
    TranslocoDirective,
    SpinnerComponent,
    CustomConfirmDialogComponent,
    CustomToastComponent,
    CustomCardComponent,
    ToastModule,
    BreadcrumbComponent,
    NavbarComponent,
    SidebarComponent,
    SidebarMobileComponent,
  ],
  providers: [ProfileLanguageStoreService, ConfirmationService],
  selector: 'customer-portal-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'customer-portal';

  constructor(
    private readonly profileLanguageStoreService: ProfileLanguageStoreService,
  ) {}

  ngOnInit(): void {
    this.profileLanguageStoreService.loadProfileLanguage();
  }
}
