<ng-container *transloco="let t">
  <customer-portal-navbar-button
    icon="search"
    [isActive]="isButtonSearchActive()"
    [isDisabled]="true"
    [label]="t('search')"
    (clickEvent)="searchOverlay.toggle($event)">
  </customer-portal-navbar-button>

  <p-overlayPanel
    #searchOverlay
    styleClass="is-caret-hidden"
    (onHide)="onToggleButtonSearchActive(false)"
    (onShow)="onToggleButtonSearchActive(true)">
    <div class="container">
      <p-inputGroup>
        <input type="text" pInputText [placeholder]="t('navbar.search')"/>
      </p-inputGroup>
    </div>
  </p-overlayPanel>
</ng-container>