<ng-container *transloco="let t">
  @if (breadcrumbs().length) {
    <p-breadcrumb
      role="navigation"
      [attr.aria-label]="t('breadcrumbAriaLabel')"
      [homeAriaLabel]="t('home')"
      [home]="home"
      [model]="breadcrumbs()">

      <ng-template
        pTemplate="item"
        let-item
        let-last="last">
        <a
          class="p-menuitem-link"
          [attr.aria-current]="last ? 'page' : null"
          [routerLink]="item.routerLink">
          @if (item.i18nKey) {
            <span>{{ t(item.i18nKey) }}</span>
          } @else {
            <span>{{ item.label }}</span>
          }
        </a>
      </ng-template>

    </p-breadcrumb>
  }
</ng-container>