import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  output,
  signal,
} from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';
import { ButtonModule } from 'primeng/button';

import { SupportBoxComponent } from '../support-box/support-box.component';
import { SIDEBAR_MENU_GROUP_LIST } from './sidebar.constants';

interface SidebarGroup {
  id: string;
  items: SidebarGroupItem[];
}

interface SidebarGroupItem {
  i18nKey: string;
  icon: string;
  id: string;
  isDisabled: boolean;
  url: string;
}

@Component({
  selector: 'customer-portal-sidebar',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    TranslocoDirective,
    ButtonModule,
    SupportBoxComponent,
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent {
  public groups = signal<SidebarGroup[]>(SIDEBAR_MENU_GROUP_LIST);

  public closeEvent = output<void>();

  onClose(): void {
    this.closeEvent.emit();
  }
}
