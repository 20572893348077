import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { environment } from '@customer-portal/environments';

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  private appInsights: ApplicationInsights;

  constructor() {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights?.instrumentationKey,
        enableAutoRouteTracking: true,
      },
    });
    this.appInsights.loadAppInsights();
    this.appInsights.trackPageView();
  }

  logPageView(name?: string, uri?: string): void {
    this.appInsights.trackPageView({
      name,
      uri,
    });
  }

  logEvent(name: string, properties?: { [key: string]: unknown }): void {
    this.appInsights.trackEvent({ name }, properties);
  }

  logMetric(
    name: string,
    average: number,
    properties?: { [key: string]: unknown },
  ): void {
    this.appInsights.trackMetric({ name, average }, properties);
  }

  logException(exception: Error, severityLevel?: number): void {
    this.appInsights.trackException({
      exception,
      severityLevel,
    });
  }

  logTrace(message: string, properties?: { [key: string]: unknown }): void {
    this.appInsights.trackTrace({ message }, properties);
  }
}
