import { Route } from '@angular/router';

export const appRoutes: Route[] = [
  {
    path: '',
    loadChildren: () =>
      import('@customer-portal/features/overview').then(
        (r) => r.OVERVIEW_ROUTES,
      ),
    data: {
      breadcrumb: null,
    },
    title: 'Overview',
  },
  {
    path: 'audits',
    loadChildren: () =>
      import('@customer-portal/features/audit').then((r) => r.AUDIT_ROUTES),
    data: {
      breadcrumb: 'audits',
    },
    title: 'Audits',
  },
  {
    path: 'findings',
    loadChildren: () =>
      import('@customer-portal/features/findings').then(
        (r) => r.FINDINGS_ROUTES,
      ),
    data: {
      breadcrumb: 'findings',
    },
    title: 'Findings',
  },
  {
    path: 'certificates',
    loadChildren: () =>
      import('@customer-portal/features/certificates').then(
        (r) => r.CERTIFICATE_ROUTES,
      ),
    data: {
      breadcrumb: 'certificates',
    },
    title: 'Certificates',
  },
  {
    path: 'schedule',
    loadChildren: () =>
      import('@customer-portal/features/schedules').then(
        (r) => r.SCHEDULES_ROUTES,
      ),
    data: {
      breadcrumb: 'schedule',
    },
    title: 'Schedule',
  },
  {
    path: 'contracts',
    loadChildren: () =>
      import('@customer-portal/features/contracts').then(
        (r) => r.CONTRACTS_ROUTES,
      ),
    data: {
      breadcrumb: 'contracts',
    },
    title: 'Contracts',
  },
  {
    path: 'financials',
    loadChildren: () =>
      import('@customer-portal/features/financials').then(
        (r) => r.FINANCIALS_ROUTES,
      ),
    data: {
      breadcrumb: 'financials',
    },
    title: 'Financials',
  },
  {
    path: 'apps',
    loadChildren: () =>
      import('@customer-portal/features/external-apps').then(
        (r) => r.EXTERNAL_APPS_ROUTES,
      ),
    data: {
      breadcrumb: 'apps',
    },
    title: 'External Apps',
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('@customer-portal/features/profile').then((r) => r.PROFILE_ROUTES),
    data: {
      breadcrumb: 'profile',
    },
    title: 'Profile',
  },
  {
    path: 'welcome',
    loadChildren: () =>
      import('@customer-portal/features/welcome').then((r) => r.WELCOME_ROUTES),
    data: {
      breadcrumb: null,
    },
    title: 'Welcome',
  },
];
