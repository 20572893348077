import { Language } from '@customer-portal/shared';

export class LanguageStorageService {
  private static readonly LANGUAGE_KEY = 'selectedLanguage';

  static saveLanguage(language: Language): void {
    localStorage.setItem(this.LANGUAGE_KEY, language);
  }

  static getLanguage(): Language {
    const storedLanguage = localStorage.getItem(this.LANGUAGE_KEY);

    return (storedLanguage as Language) || Language.English;
  }

  static clearLanguage(): void {
    localStorage.removeItem(this.LANGUAGE_KEY);
  }
}
