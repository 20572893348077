<ng-container *transloco="let t">
  <nav>
    <div class="wrapper">
      <customer-portal-navbar-button
        class="mobile-menu"
        icon="bars"
        [ariaLabel]="t('toggleMenu')"
        (clickEvent)="onToggleSidebar(true)">
      </customer-portal-navbar-button>

      <customer-portal-navbar-search></customer-portal-navbar-search>
    </div>

    <div class="wrapper">
      <customer-portal-navbar-button
        icon="custom-check-list"
        iconPosition="right"
        [badgeCounter]="2"
        [isDisabled]="true"
        [label]="t('actions')">
      </customer-portal-navbar-button>

      <customer-portal-navbar-button
        icon="bell"
        iconPosition="right"
        [badgeCounter]="4"
        [isDisabled]="true"
        [label]="t('notifications')">
      </customer-portal-navbar-button>

      <customer-portal-navbar-settings></customer-portal-navbar-settings>
    </div>
  </nav>
</ng-container>
