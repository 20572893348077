<ng-container *transloco="let t">
  <aside role="complementary">
    <div class="logo">
      <img alt="DNV logo" src="/assets/logos/dnv-logo.svg"/>
      <p-button
        class="close"
        icon="pi pi-times"
        role="button"
        tabindex="0"
        [ariaLabel]="t('closeMenu')"
        [rounded]="true"
        [text]="true"
        (keydown.enter)="onClose()"
        (keydown.space)="onClose()"
        (onClick)="onClose()">
      </p-button>
    </div>

    <nav aria-label="t('sidebar')" role="navigation">
      @for (group of groups(); track group.id; let last = $last) {
        <div class="grouping">
          @for (item of group.items; track item.id) {
            <p-button
              ariaCurrentWhenActive="page"
              routerLinkActive="is-active"
              [attr.aria-label]="t(item.i18nKey)"
              [disabled]="item.isDisabled"
              icon="pi pi-{{ item.icon }}"
              [label]="t(item.i18nKey)"
              [routerLink]="item.url"
              [text]="true"
              (onClick)="onClose()">
            </p-button>
          }
        </div>
        @if (!last) {
          <tr aria-hidden="true"></tr>
        }
      }
    </nav>

    <div class="support" role="complementary">
      <customer-portal-support-box></customer-portal-support-box>
    </div>
  </aside>
</ng-container>
