import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'customer-portal-support-box',
  standalone: true,
  imports: [CommonModule, TranslocoDirective, ButtonModule],
  templateUrl: './support-box.component.html',
  styleUrl: './support-box.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupportBoxComponent {}
