import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, output } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';

import { NavbarButtonComponent } from '../navbar-button/navbar-button.component';
import { NavbarSearchComponent } from '../navbar-search/navbar-search.component';
import { NavbarSettingsComponent } from '../navbar-settings/navbar-settings.component';

@Component({
  selector: 'customer-portal-navbar',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoDirective,
    NavbarButtonComponent,
    NavbarSearchComponent,
    NavbarSettingsComponent,
  ],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent {
  public toggleSidebarEvent = output<boolean>();

  onToggleSidebar(value: boolean): void {
    this.toggleSidebarEvent.emit(value);
  }
}
